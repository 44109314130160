import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Oaxaca - Mexico | Kiwi & Cacahuate"
      description="A week in Oaxaca"
      url="https://kiwicacahuate.com/oaxaca/"
      image="https://kiwicacahuate.com/oaxaca/oaxaca.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/oaxaca.webp, /oaxaca/oaxaca.webp 2x" />
                <source srcSet="/oaxaca/oaxaca.jpg, /oaxaca/oaxaca.jpg 2x" />
                <img src="/oaxaca/oaxaca.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/001.webp, /oaxaca/thumbnail/001-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/001.jpg, /oaxaca/thumbnail/001-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/001.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/002.webp, /oaxaca/thumbnail/002-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/002.jpg, /oaxaca/thumbnail/002-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/002.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/003.webp, /oaxaca/thumbnail/003-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/003.jpg, /oaxaca/thumbnail/003-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/003.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/004.webp, /oaxaca/thumbnail/004-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/004.jpg, /oaxaca/thumbnail/004-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/004.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/005.webp, /oaxaca/thumbnail/005-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/005.jpg, /oaxaca/thumbnail/005-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/005.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/006.webp, /oaxaca/thumbnail/006-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/006.jpg, /oaxaca/thumbnail/006-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/006.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/007.webp, /oaxaca/thumbnail/007-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/007.jpg, /oaxaca/thumbnail/007-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/007.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/008.webp, /oaxaca/thumbnail/008-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/008.jpg, /oaxaca/thumbnail/008-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/008.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/009.webp, /oaxaca/thumbnail/009-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/009.jpg, /oaxaca/thumbnail/009-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/009.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/010.webp, /oaxaca/thumbnail/010-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/010.jpg, /oaxaca/thumbnail/010-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/010.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/011.webp, /oaxaca/thumbnail/011-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/011.jpg, /oaxaca/thumbnail/011-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/011.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/012.webp, /oaxaca/thumbnail/012-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/012.jpg, /oaxaca/thumbnail/012-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/012.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/013.webp, /oaxaca/thumbnail/013-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/013.jpg, /oaxaca/thumbnail/013-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/013.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/014.webp, /oaxaca/thumbnail/014-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/014.jpg, /oaxaca/thumbnail/014-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/014.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/015.webp, /oaxaca/thumbnail/015-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/015.jpg, /oaxaca/thumbnail/015-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/015.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/016.webp, /oaxaca/thumbnail/016-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/016.jpg, /oaxaca/thumbnail/016-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/016.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/017.webp, /oaxaca/thumbnail/017-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/017.jpg, /oaxaca/thumbnail/017-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/017.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/019.webp, /oaxaca/thumbnail/019-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/019.jpg, /oaxaca/thumbnail/019-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/019.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/020.webp, /oaxaca/thumbnail/020-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/020.jpg, /oaxaca/thumbnail/020-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/020.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/021.webp, /oaxaca/thumbnail/021-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/021.jpg, /oaxaca/thumbnail/021-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/021.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/022.webp, /oaxaca/thumbnail/022-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/022.jpg, /oaxaca/thumbnail/022-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/022.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/023.webp, /oaxaca/thumbnail/023-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/023.jpg, /oaxaca/thumbnail/023-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/023.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/024.webp, /oaxaca/thumbnail/024-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/024.jpg, /oaxaca/thumbnail/024-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/024.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/025.webp, /oaxaca/thumbnail/025-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/025.jpg, /oaxaca/thumbnail/025-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/025.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/026.webp, /oaxaca/thumbnail/026-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/026.jpg, /oaxaca/thumbnail/026-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/026.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/027.webp, /oaxaca/thumbnail/027-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/027.jpg, /oaxaca/thumbnail/027-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/027.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/028.webp, /oaxaca/thumbnail/028-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/028.jpg, /oaxaca/thumbnail/028-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/028.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/029.webp, /oaxaca/thumbnail/029-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/029.jpg, /oaxaca/thumbnail/029-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/029.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/030.webp, /oaxaca/thumbnail/030-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/030.jpg, /oaxaca/thumbnail/030-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/030.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/031.webp, /oaxaca/thumbnail/031-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/031.jpg, /oaxaca/thumbnail/031-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/031.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/032.webp, /oaxaca/thumbnail/032-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/032.jpg, /oaxaca/thumbnail/032-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/032.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/033.webp, /oaxaca/thumbnail/033-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/033.jpg, /oaxaca/thumbnail/033-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/033.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/034.webp, /oaxaca/thumbnail/034-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/034.jpg, /oaxaca/thumbnail/034-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/034.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/035.webp, /oaxaca/thumbnail/035-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/035.jpg, /oaxaca/thumbnail/035-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/035.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/036.webp, /oaxaca/thumbnail/036-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/036.jpg, /oaxaca/thumbnail/036-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/036.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/037.webp, /oaxaca/thumbnail/037-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/037.jpg, /oaxaca/thumbnail/037-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/037.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/038.webp, /oaxaca/thumbnail/038-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/038.jpg, /oaxaca/thumbnail/038-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/038.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/039.webp, /oaxaca/thumbnail/039-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/039.jpg, /oaxaca/thumbnail/039-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/039.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/040.webp, /oaxaca/thumbnail/040-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/040.jpg, /oaxaca/thumbnail/040-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/040.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/041.webp, /oaxaca/thumbnail/041-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/041.jpg, /oaxaca/thumbnail/041-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/041.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/042.webp, /oaxaca/thumbnail/042-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/042.jpg, /oaxaca/thumbnail/042-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/042.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/043.webp, /oaxaca/thumbnail/043-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/043.jpg, /oaxaca/thumbnail/043-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/043.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/044.webp, /oaxaca/thumbnail/044-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/044.jpg, /oaxaca/thumbnail/044-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/044.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/045.webp, /oaxaca/thumbnail/045-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/045.jpg, /oaxaca/thumbnail/045-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/045.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/046.webp, /oaxaca/thumbnail/046-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/046.jpg, /oaxaca/thumbnail/046-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/046.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/047.webp, /oaxaca/thumbnail/047-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/047.jpg, /oaxaca/thumbnail/047-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/047.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/048.webp, /oaxaca/thumbnail/048-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/048.jpg, /oaxaca/thumbnail/048-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/048.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/049.webp, /oaxaca/thumbnail/049-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/049.jpg, /oaxaca/thumbnail/049-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/049.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/050.webp, /oaxaca/thumbnail/050-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/050.jpg, /oaxaca/thumbnail/050-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/050.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/051.webp, /oaxaca/thumbnail/051-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/051.jpg, /oaxaca/thumbnail/051-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/051.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/052.webp, /oaxaca/thumbnail/052-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/052.jpg, /oaxaca/thumbnail/052-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/052.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/053.webp, /oaxaca/thumbnail/053-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/053.jpg, /oaxaca/thumbnail/053-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/053.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/054.webp, /oaxaca/thumbnail/054-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/054.jpg, /oaxaca/thumbnail/054-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/054.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/055.webp, /oaxaca/thumbnail/055-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/055.jpg, /oaxaca/thumbnail/055-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/055.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/056.webp, /oaxaca/thumbnail/056-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/056.jpg, /oaxaca/thumbnail/056-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/056.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/057.webp, /oaxaca/thumbnail/057-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/057.jpg, /oaxaca/thumbnail/057-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/057.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/058.webp, /oaxaca/thumbnail/058-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/058.jpg, /oaxaca/thumbnail/058-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/058.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/059.webp, /oaxaca/thumbnail/059-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/059.jpg, /oaxaca/thumbnail/059-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/059.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/060.webp, /oaxaca/thumbnail/060-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/060.jpg, /oaxaca/thumbnail/060-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/060.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/061.webp, /oaxaca/thumbnail/061-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/061.jpg, /oaxaca/thumbnail/061-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/061.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/062.webp, /oaxaca/thumbnail/062-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/062.jpg, /oaxaca/thumbnail/062-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/062.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/063.webp, /oaxaca/thumbnail/063-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/063.jpg, /oaxaca/thumbnail/063-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/063.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/064.webp, /oaxaca/thumbnail/064-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/064.jpg, /oaxaca/thumbnail/064-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/064.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/065.webp, /oaxaca/thumbnail/065-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/065.jpg, /oaxaca/thumbnail/065-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/065.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/066.webp, /oaxaca/thumbnail/066-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/066.jpg, /oaxaca/thumbnail/066-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/066.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/067.webp, /oaxaca/thumbnail/067-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/067.jpg, /oaxaca/thumbnail/067-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/067.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/068.webp, /oaxaca/thumbnail/068-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/068.jpg, /oaxaca/thumbnail/068-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/068.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/069.webp, /oaxaca/thumbnail/069-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/069.jpg, /oaxaca/thumbnail/069-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/069.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/070.webp, /oaxaca/thumbnail/070-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/070.jpg, /oaxaca/thumbnail/070-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/070.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/071.webp, /oaxaca/thumbnail/071-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/071.jpg, /oaxaca/thumbnail/071-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/071.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/072.webp, /oaxaca/thumbnail/072-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/072.jpg, /oaxaca/thumbnail/072-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/072.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/073.webp, /oaxaca/thumbnail/073-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/073.jpg, /oaxaca/thumbnail/073-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/073.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/074.webp, /oaxaca/thumbnail/074-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/074.jpg, /oaxaca/thumbnail/074-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/074.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/075.webp, /oaxaca/thumbnail/075-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/075.jpg, /oaxaca/thumbnail/075-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/075.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/oaxaca/thumbnail/076.webp, /oaxaca/thumbnail/076-2x.webp 2x" />
                <source srcSet="/oaxaca/thumbnail/076.jpg, /oaxaca/thumbnail/076-2x.jpg 2x" />
                <img
                  src="/oaxaca/thumbnail/076.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
